import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export const GlobalAlertOptions = {
  id: 'global-alerts',
  autoClose: false,
  keepAfterRouteChange: false
};

export const alertService = {
  onAlert,
  success,
  error,
  info,
  warn,
  alert,
  clear
};
export interface AlertOptions {
  id?: string;
  autoClose?: boolean;
  keepAfterRouteChange?: boolean;
}
export interface AlertSubject extends AlertOptions {
  id?: string;
  message?: string;
  type?: string;
}

export const AlertType = {
  Success: 'Success',
  Error: 'Error',
  Info: 'Info',
  Warning: 'Warning'
};

const alertSubject: Subject<AlertSubject> = new Subject();
const defaultId = 'default-alert';

// enable subscribing to alerts observable
function onAlert(id: string = defaultId) {
  //@ts-ignore
  return alertSubject.asObservable().pipe(filter(x => x && x.id === id));
}

// convenience methods
function success(message: string, options: AlertOptions) {
  alert({ ...options, type: AlertType.Success, message });
}

function error(message: string, options: AlertOptions) {
  alert({ ...options, type: AlertType.Error, message });
}

function info(message: string, options: AlertOptions) {
  alert({ ...options, type: AlertType.Info, message });
}

function warn(message: string, options: AlertOptions) {
  alert({ ...options, type: AlertType.Warning, message });
}

// core alert method
function alert(alert: AlertSubject) {
  alertSubject.next(alert);
}

// clear alerts
function clear(id = defaultId) {
  alertSubject.next({ id });
}
