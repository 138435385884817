import '../styles/base.css';
import '../styles/globals.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-tooltip/dist/react-tooltip.css';
import { AlertProvider } from 'components/layout/AlertProvider';
import { AppProps } from 'next/app';
import { IronSessionData } from 'iron-session';
import { NextPageWithLayout } from 'types/app';
import { Roboto_Condensed, Source_Sans_Pro } from '@next/font/google';
import { SessionProvider } from 'components/layout/SessionProvider';
import Head from 'next/head';
import React from 'react';
import Script from 'next/script';

interface MyAppProps extends AppProps {
  session: IronSessionData;
}

type AppPropsWithLayout = MyAppProps & {
  Component: NextPageWithLayout;
};

const robotoCondensed = Roboto_Condensed({
  weight: ['400', '700'],
  subsets: ['latin']
});

const sourceSansPro = Source_Sans_Pro({
  weight: ['400', '700'],
  subsets: ['latin']
});

export default function MyApp(props: AppPropsWithLayout) {
  const { Component, pageProps, session } = props;
  const getLayout = Component.getLayout ?? (page => page);

  return (
    <>
      {/* eslint-disable-next-line */}
      <style jsx global>{`
        :root {
          --roboto-condensed-font: ${robotoCondensed.style.fontFamily};
          --source-sans-pro-font: ${sourceSansPro.style.fontFamily};
        }
      `}</style>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <SessionProvider session={session}>
        <AlertProvider id="global-alerts">
          <div className="font-source">
            {getLayout(<Component {...pageProps} />)}
          </div>
        </AlertProvider>
      </SessionProvider>

      {/* Global site tag (gtag.js) - Google Analytics */}
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-1HPT3R9Q3V"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'G-1HPT3R9Q3V');
        `}
      </Script>
    </>
  );
}
