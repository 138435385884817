import { IronSessionData } from 'iron-session';
import { SessionUser } from 'modules/users/types';
import React, { useState } from 'react';

export interface SessionState {
  user?: SessionUser;
  needsPayment?: boolean;
}
export type SessionContextT = [SessionState, (newState: SessionState) => void];

interface SessionProviderProps {
  children: any;
  session?: IronSessionData;
}

const DEFAULT_STATE: SessionState = {
  user: undefined,
  needsPayment: false
};

const SessionContext = React.createContext<SessionContextT>([
  DEFAULT_STATE,
  () => {}
]);

const SessionProvider = (props: SessionProviderProps) => {
  const [state, setState] = useState<SessionState>({
    ...DEFAULT_STATE,
    ...(props.session?.user && { user: props.session.user })
  });

  const compareObjects = (a: any, b: any) => {
    if (a === b) return true;

    if (typeof a != 'object' || typeof b != 'object' || a == null || b == null)
      return false;

    let keysA = Object.keys(a),
      keysB = Object.keys(b);

    if (keysA.length != keysB.length) return false;

    for (let key of keysA) {
      if (!keysB.includes(key)) return false;

      if (typeof a[key] === 'function' || typeof b[key] === 'function') {
        if (a[key].toString() != b[key].toString()) return false;
      } else {
        if (!compareObjects(a[key], b[key])) return false;
      }
    }

    return true;
  };
  const setuserState = (newState: SessionState) => {
    if (compareObjects(state, newState)) return;
    setState(newState);
  };

  return (
    <SessionContext.Provider value={[state, setuserState]}>
      {props.children}
    </SessionContext.Provider>
  );
};

export { SessionContext, SessionProvider };
